import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import SEO from "@src/components/SEO";
import { Heading, BodyText, Button } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import { logEvent } from "@src/utils/logClient";
import SchoolwidePointsHero from "@src/components/partials/schoolwide-points/SchoolwidePointsHero";
import SchoolwidePointsContent from "@src/components/partials/schoolwide-points/SchoolwidePointsContent";
import SchoolwidePointsForm from "@src/components/partials/schoolwide-points/SchoolwidePointsForm";
import { graphql, useStaticQuery } from "gatsby";
import CommonModal from "@src/components/modals/CommonModal";
import Translate from "@src/components/translation/Translate";

const InputText = styled("input")`
  display: block;
  margin-bottom: 12px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 99px;
  border: 2px solid rgb(211, 215, 236);
  background-color: rgb(247, 248, 255);

    &.confirmEmailField {
      display: none;
    }
  }

`;
const SchoolwidePoints = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      directus {
        page_schoolwide_points {
          feature_1_image {
            filename_disk
          }
          feature_2_image {
            filename_disk
          }
          feature_3_image {
            filename_disk
          }
        }
      }
    }
  `);

  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    logEvent({
      eventName: "web.external.schoolwide_points.load_page",
      eventValue: location.href,
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("success") !== null) {
      setShowSuccess(true);
      logEvent({
        eventName: "web.external.schoolwide_points.success",
        eventValue: window.location.href,
      });
    }
  }, []);

  const closeSuccessModal = () => {
    logEvent("web.external.schoolwide_points.success.close");
    setShowSuccess(false);
    const url = new URL(window.location.href);
    if (url.searchParams.has("success")) {
      url.searchParams.delete("success");
      window.history.pushState({}, "", url);
    }
  };

  const closeModal = () => {
    logEvent("web.external.schoolwide_points.form_modal.close");
    setShowFormModal(false);
  };

  const [formData, setFormData] = useState({
    email: "",
    title: "",
    confirmEmail: "",
  });

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();

    if (formData.confirmEmail) {
      ev.preventDefault();
      return;
    }
    const email = formData.email.toLowerCase();
    logEvent({
      eventName: "web.external.schoolwide_points.submit_lead_gen_form",
      eventValue: email,
    });
    const form = ev.target as HTMLFormElement;
    form.submit();
  }

  function handleInputChange(ev: ChangeEvent<HTMLInputElement>) {
    const { name, value } = ev.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  return (
    <>
      <SEO
        title="directus.page_schoolwide_points.Hero_title"
        description="directus.page_schoolwide_points.Hero_subtitle"
        noindex={true}
      />
      <SchoolwidePointsHero setShowFormModal={setShowFormModal} />
      <SchoolwidePointsContent
        feature1={data.directus.page_schoolwide_points.feature_1_image.filename_disk}
        feature2={data.directus.page_schoolwide_points.feature_2_image.filename_disk}
        feature3={data.directus.page_schoolwide_points.feature_3_image.filename_disk}
      />
      <SchoolwidePointsForm setShowFormModal={setShowFormModal} />
      {showFormModal && (
        <CommonModal noHeader closeModal={closeModal}>
          <form
            action="https://learn.classdojo.com/l/1046033/2024-05-31/b6ly"
            method="post"
            onSubmit={handleSubmit}
            css={{
              width: "80%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 12,
              paddingBlock: 36,
            }}
          >
            <BodyText css={{ fontWeight: 700, marginBottom: 12 }}>
              <Translate path="directus.page_schoolwide_points.modal_form_text" />
            </BodyText>

            <InputText
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email Address"
              required
            />
            <InputText
              name="title"
              type="text"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Your Job Title"
              required
            />
            <InputText
              className="confirmEmailField"
              type="text"
              name="confirmEmail"
              value={formData.confirmEmail}
              placeholder="Confirm your work e-mail address"
              onChange={handleInputChange}
            />
            <Button kind="plus" type="submit" css={{ width: "100%" }}>
              <Translate path="layouts.main.submit" />
            </Button>
          </form>
        </CommonModal>
      )}
      {showSuccess && (
        <CommonModal closeModal={closeSuccessModal}>
          <Heading>
            <Translate path="directus.page_schoolwide_points.modal_form_success_title" />
          </Heading>
          <BodyText>
            <Translate path="directus.page_schoolwide_points.modal_success_subtitle" />
          </BodyText>
        </CommonModal>
      )}
    </>
  );
};

export default SchoolwidePoints;
